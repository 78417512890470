import React, { FC, useEffect } from 'react'
import { BorderedPageLayout } from '../../../pages/BorderedPageLayout'
import { PatientItem } from '../../ListItems/PatientItem'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { handleOrderInfoModal, handleProfileEditModal } from '../../../features/modals/modalsSlice'
import { ModalContentProps } from '../../Modal'
import { InputField } from '../../InputField'
import { YellowButton } from '../../YellowButton'
import { cardAdd, handleEditProfileGender, handleProfileForm, setDefaultProfileForm } from '../../../features/profile/profileSlice'
import { ProfileEditTextFields, ProfileData } from '../../../types/entities/user.types'
import useTheme from '../../../hooks/useAppColor'
import { CARD_MASK } from '../../../config/masks'

export const ProfileModalContent: FC<ModalContentProps> = ({ handleModal, level }) => {
    const dispatch = useAppDispatch()
    const getTheme = useTheme()
    
    const { form, data, edit_form } = useAppSelector(state => state.profile)
    const formAndDataEqual = Object.keys(form).every((key) => form[key as keyof ProfileEditTextFields] === data[key as keyof ProfileData])

    const sendEditProfile = () => {
        const normalizedCard = form.card_number.split(" ").join("")
        const numberedCard = Number(normalizedCard)
        dispatch(cardAdd({ card_number: numberedCard }))
    }

    useEffect(() => {
        return () => {
            dispatch(setDefaultProfileForm())
        }
    }, [])

    return (
        <BorderedPageLayout
            modal={{ level: level || 1 }}
            handleModal={handleModal}
            contentClassName='f-column gap-40'>
            <div className="d-f jc-between">
                <div onClick={handleModal} className="textButton fz-l">Закрыть</div>
                <p className={`${getTheme("c-dark", "c-lg")} fw-5 fz-l fw-6`}>Личные данные</p>
                <div className='f-025'></div>
            </div>
            <div className="f-column gap-30">
                <InputField
                    error={""}
                    value={form.last_name}
                    onChange={e => dispatch(handleProfileForm({ key: "last_name", val: e.target.value }))}
                    placeholder='Иванов'
                    label='Фамилия' />
                <InputField
                    error={""}
                    value={form.first_name}
                    onChange={e => dispatch(handleProfileForm({ key: "first_name", val: e.target.value }))}
                    placeholder='Иван'
                    label='Имя' />
                <InputField
                    value={form.subname}
                    onChange={e => dispatch(handleProfileForm({ key: "subname", val: e.target.value }))}
                    placeholder='Иванович'
                    label='Отчество' />
                <InputField
                    value={form.card_number == "0" ? "" : form.card_number}
                    onChange={e => dispatch(handleProfileForm({ key: "card_number", val: e.target.value }))}
                    mask={CARD_MASK}
                    placeholder='0000 0000 0000 0000'
                    label='Номер карты' />
                <div className="f-column gap-15">
                    <p className='fz-m fw-5'>Ваш пол</p>
                    <div className="f-row-betw gap-15">
                        <YellowButton onClick={() => dispatch(handleEditProfileGender(true))} isFilled={form.gender} className={"f-1"}>Мужчина</YellowButton>
                        <YellowButton onClick={() => dispatch(handleEditProfileGender(false))} isFilled={!form.gender} className={"f-1"}>Женщина</YellowButton>
                    </div>
                </div>
            </div>
            <div className="f-column gap-5">
                <YellowButton
                    disabled={form.card_number.includes("_") || form.card_number.length < 19 || formAndDataEqual}
                    onClick={sendEditProfile}
                    loading={edit_form.sending}>
                    Сохранить
                </YellowButton>
                {
                    edit_form.err ? <p className='fz-m c-error'>{edit_form.err}</p> : null
                }
            </div>


        </BorderedPageLayout >
    )
}
